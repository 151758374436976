<template>
     <v-container class="home__questions">
    <v-row>
        <v-col cols="12" md="6">
            <p class="home__questions--FAQ">الأسئلة الشائعة</p>
            <h3 class="home__questions__title">
      الأسئلـة الشائعـة
      <br />
      مـع كاشيـب
      <!-- <img
        class="home__questions__title__qoutes"
        src="@/assets/images/landing-page/qoutes.svg"
        alt="qoutes"
      /> -->
    </h3>
    <p class="home__questions__subtitle">اكتشف إجابات لأكثر الأسئلة شيوعًا حول منصتنا الحديثة لإدارة الخدمات اللوجستية.</p>
        </v-col>
  <v-col cols="12" md="6">
      <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel
        v-for="(item, index) in panels"
        :key="index"
        class="home__questions__faq-panel"
      >
        <v-expansion-panel-header>
          <template>
            <div class="home__questions__faq-panel--header">
              <span>{{ item.title }}</span>
            
            </div>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="home__questions__faq-panel--text">
          {{ item.content }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {
  data() {
    return {
      panel: [0], // To track opened/closed panels
      panels: [
        {
          id: 1,
          title: "كيف يُحسّن كاشيب إدارة الخدمات اللوجستية؟",
          content:
            "يستخدم كاشيب خوارزميات متقدمة وبيانات في الوقت الفعلي لتحسين العمليات اللوجستية، مما يضمن الكفاءة وتقليل التكلفة.",
        },
        {
          id: 2,
          title: "هل يمكن دمج كاشيب مع الأنظمة الحالية؟",
          content:
            "نعم، تم تصميم كاشيب للتكامل بسهولة مع أنظمة الخدمات اللوجستية وسلاسل التوريد الحالية، مما يُحسن قدراتها.",
        },
        {
          id: 3,
          title: "ما الدعم الذي يُقدمه كاشيب للمستخدمين الجدد؟",
          content:
            "نقدم دعمًا شاملاً للتدريب وخدمة العملاء على مدار الساعة لضمان تحقيق أقصى استفادة من منصة كاشيب.",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.home__questions {
    margin-top: 5rem;
    margin-bottom: 5rem;
    &--FAQ{
        font-size: 0.875rem;
        color: #5C7AAB;
        margin-bottom: 1.5rem !important;
    }
    &__title {
      font-weight: 400;
      font-size: 2.988rem;
      line-height: 3rem;
      position: relative;
      display: inline-block;
    }
    &__subtitle{
        color: #475569;
        font-size: 1rem;
        font-weight: 400;
        margin-top: 1.625rem;
        margin-bottom: 0rem;
    }
    &__faq-panel{
        margin-bottom: 2rem !important;
        padding: 0.813rem;
        &--header {
            font-size: 1.25rem;
            color: #0F172A;
            font-weight: 400;
            line-height: 1.75rem;
            transition: margin-bottom 0.3s ease; 
        }
    .v-expansion-panel--active &--header {
      margin-bottom: 1.625rem;
    }

    &:not(.v-expansion-panel--active) &--header {
      margin-bottom: 0;
    }
  
        &--text {
            font-size: 1.125rem;
            color: #475569;
            line-height: 1.5rem;
        }
    }
}
.v-application .v-expansion-panel--active > .v-expansion-panel-header, .v-application .v-expansion-panel-header {
    display: flex !important;
    align-items: baseline !important;
}
::v-deep .v-expansion-panel-content {
  transition: none !important;
}
.v-application .v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--active {
    background-color: #FFFF !important;
}
</style>
