import Vue from "vue";
import http from "./plugins/http";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import visibility from "./common/directives/visibility";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n"; // Import vue-i18n
import "leaflet/dist/leaflet.css";

// Import localization files
import ar from "@/i18n/ar.json";
import en from "@/i18n/en.json";

// Use Vue plugins
Vue.use(VueTelInputVuetify, { vuetify });
Vue.use(VueI18n);
Vue.directive("visibility", visibility);
Vue.prototype.http = http;

Vue.config.productionTip = false;

// Configure i18n
const messages = { ar, en }; // Localization messages
const i18n = new VueI18n({
  locale: "ar", // Default language
  fallbackLocale: "en", // Fallback language
  messages, // Message object
});

// Create and mount Vue instance
new Vue({
  i18n, // Add i18n instance
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
