<template>
  <v-container class="py-0 advantages-section">
    <v-row>
      <v-col cols="12" class="py-0">
        <p class="text-uppercase advantages-section--title">
          حلول لوجستية مبتكرة
        </p>
        <h3 class="advantages-section--header">اكتشف المزايا</h3>
        <p class="advantages-section--secondary-header">
          اكتشف كيف تُحدث تقنيات كاشيب المتطورة ثورة في إدارة الخدمات اللوجستية.
          من العمليات المدفوعة بالذكاء الاصطناعي إلى رؤى البيانات في الوقت
          الفعلي، نُعزز سلسلة التوريد الخاصة بك بالكفاءة والدقة.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" class="py-0">
        <v-card>
          <div class="advantages-section--icon">
            <svg class="svg-inline--fa fa-brain" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="brain" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M184 0c30.9 0 56 25.1 56 56V456c0 30.9-25.1 56-56 56c-28.9 0-52.7-21.9-55.7-50.1c-5.2 1.4-10.7 2.1-16.3 2.1c-35.3 0-64-28.7-64-64c0-7.4 1.3-14.6 3.6-21.2C21.4 367.4 0 338.2 0 304c0-31.9 18.7-59.5 45.8-72.3C37.1 220.8 32 207 32 192c0-30.7 21.6-56.3 50.4-62.6C80.8 123.9 80 118 80 112c0-29.9 20.6-55.1 48.3-62.1C131.3 21.9 155.1 0 184 0zM328 0c28.9 0 52.6 21.9 55.7 49.9c27.8 7 48.3 32.1 48.3 62.1c0 6-.8 11.9-2.4 17.4c28.8 6.2 50.4 31.9 50.4 62.6c0 15-5.1 28.8-13.8 39.7C493.3 244.5 512 272.1 512 304c0 34.2-21.4 63.4-51.6 74.8c2.3 6.6 3.6 13.8 3.6 21.2c0 35.3-28.7 64-64 64c-5.6 0-11.1-.7-16.3-2.1c-3 28.2-26.8 50.1-55.7 50.1c-30.9 0-56-25.1-56-56V56c0-30.9 25.1-56 56-56z"></path></svg>
          </div>
          <h3 class="advantages-section__content--title">
            تصنيف الكود الجمركي بالذكاء الاصطناعي
          </h3>
          <p class="text-secondary">
            تطور كاشيب خوارزميات ذكاء اصطناعي لتحليل وفهم بيانات الشحن بدقة
            وتصنيفها حسب الأكواد الجمركية، مما يقلل الأخطاء اليدوية ويُسرّع
            عملية التخليص الجمركي.
          </p>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-card>
          <div class="advantages-section--icon">
            <svg class="svg-inline--fa fa-file-lines" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-lines" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="currentColor" d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z"></path></svg>
          </div>
          <h3 class="advantages-section__content--title">رقمنة المستندات</h3>
          <p class="text-secondary">
            في كاشيب، نُطور نظامًا لتحويل جميع المستندات إلى صيغة رقمية موحدة،
            مما يُسهّل تبادلها ومشاركتها بين جميع الأطراف.
          </p>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-card>
          <div class="advantages-section--icon">
            <svg class="svg-inline--fa fa-location-dot" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="location-dot" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="currentColor" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"></path></svg>
          </div>
          <h3 class="advantages-section__content--title">معالجة البيانات الضخمة</h3>
          <p class="text-secondary">
            تستخدم كاشيب تقنيات معالجة البيانات الضخمة لتحليل البيانات في الوقت
            الفعلي، مما يُساعد على تحسين التخطيط واتخاذ قرارات أسرع وتقليل
            التأخيرات غير المتوقعة.
          </p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AdvantagesSection",
};
</script>

<style lang="scss" scoped>
.advantages-section {
  margin-top: 5rem;
  margin-bottom: 5rem;
  &--title {
    font-size: 1.25rem;
    color: #5c7aab;
    font-weight: 400;
    margin-bottom: 1.5rem;
  }
  &--header {
    font-size: 2.23rem;
    font-weight: 400;
    color: #0f172a;
  }
  &--secondary-header {
    font-size: 2.23rem;
    font-weight: 400;
    color: #64748b;
    margin-bottom: 4rem;
  }
  &__content {
    &--title{
      font-size: 1.5rem;
      font-weight: 400;
      margin-bottom: 0.563rem;
    }
  }
  .text-secondary {
    font-weight: 400;
    color: #334155;
    font-size: 1.125rem;
    margin-bottom: 0rem;
  }
  &--icon {
    border: 0.063rem solid #5c7aab;
    border-radius: 0.75rem !important;
    margin-bottom: 1.5rem;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
     overflow: hidden; 
    svg {
    color: #5c7aab;
    font-size: 1.25rem;
    width: 0.938rem;
    height: 1.25rem;
    }
  }
  img {
    width: 100%;
    height:100%;
  }
}
::v-deep .v-sheet.v-card {
  box-shadow: unset !important;
}
</style>
