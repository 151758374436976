<template>
  <div class="steps-section">
    <v-container>
    <v-row justify="space-between" align="center" class="steps-section__header">
      <v-col>
        <h3 class="steps-section__header--header">
          اكتشف كيف تُحدث كاشيب تحولاً في مجال اللوجستيات
        </h3>
        <p class="steps-section__header--text">
          جرّب إدارة سلسة للوجستيات مع منصة كاشيب المتطورة، المصممة لتبسيط عمليات الشحن الخاصة بك.
        </p>
      </v-col>
        <v-col cols="12" md="6">
        <img class="steps-section__header--img" src="../../assets/images/landing-page/steps-bg.png" />
      </v-col>
    </v-row>
    <v-row class="step__content" justify="space-between" align="center">
      <v-col cols="12" md="4" v-for="(step, index) in steps" :key="index">
        <div class="step">
          <div class="step-number">
            <img :src="step.icon" />
          </div>
          <h4 class="step-title">
            {{ step.title }}
          </h4>
          <p class="step-description">
            {{ step.description }}
          </p>
        </div>
      </v-col>
    </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      steps: [
        {
          icon: require("../../assets/images/landing-page/step1.svg"),
          title: "أنشئ حسابك واحصل على دعم الخبراء",
          description:
            "قم بالتسجيل في كاشيب وتواصل مع خبراء الخدمات اللوجستية لدينا للحصول على مساعدة شخصية تلبي احتياجاتك.",
        },
        {
          icon: require("../../assets/images/landing-page/step2.svg"),
          title: "ابدأ شحنتك",
          description:
            "قم بإدخال تفاصيل الشحنة بسهولة ودع كاشيب تتولى الباقي لضمان معالجة فعالة من البداية إلى النهاية.",
        },
        {
          icon: require("../../assets/images/landing-page/step3.svg"),
          title: "ابقَ على اطلاع بتتبع الوقت الفعلي",
          description:
            "راقب شحناتك في الوقت الفعلي من خلال لوحة التحكم البديهية لدينا، مما يوفر لك تحديثات دقيقة.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.steps-section {
  background-color: #C7DBEE;
  &__header{
    padding-top: 3.25rem;
    margin-bottom: 4.411rem;
      &--img {
  }
  &--header {
    font-size: 2.241rem;
    font-weight: 400;
    line-height: 2.5rem;
    margin-bottom: 1.625rem;
  }
  &--text{
    font-size: 1rem;
    color: #475569;
    font-weight: 400;
    margin-bottom: 0rem;
  }

  }

  .step {
    position: relative;
    border-top: 0.063rem solid #8da9cd;
    padding-top: 3.438rem;
    min-height: 12.5rem;
    &__content {
      margin-bottom: 2.438rem;
    }
    .step-border {
      content: "";
      display: block;
      width: 100%;
      height: 0.063rem;
      background-color: #ccc; /* Light gray for the border */
      margin-bottom: 1rem;
      margin-inline-start: 1.5rem;
    }

    .step-number {
      position: absolute;
      top: -1.625rem;
      right: 0;
      width: 4.5rem;
      background-color: #C7DBEE;
      img {
        width: 3rem;
        height: 3rem;
      }
    }

    .step-title {
      font-size: 1.125rem;
      font-weight: 400;
      margin-bottom: 1.125rem;
      color: #0F172A;
    }

    .step-description {
      font-size: 1rem;
      color: #666;
      margin-bottom: 0rem;
    }
  }
}
</style>
