<template>
  <v-container class="latest">
    <v-row class="latest__header text-center">
      <v-col>
        <h2 class="latest__header--title">
          أحدث الأفكار في مجال الخدمات اللوجستية
        </h2>
        <p class="latest__header--subtitle">
          ابقَ على اطلاع بأحدث المقالات حول إدارة الخدمات اللوجستية والابتكار.
        </p>
      </v-col>
    </v-row>
    <v-row justify="center" class="insight-cards">
      <v-col
        cols="12"
        sm="12"
        md="4"
        v-for="(card, index) in cards"
        :key="index"
      >
        <v-img :src="card.image" class="card-image" height="12.5rem"></v-img>
        <div class="latest__content">
          <div class="latest__content__details">
            <p class="latest__content__details--date">{{ card.date }}</p>
            <p class="latest__content__details--category">
              {{ card.category }}
            </p>
          </div>
          <h3 class="latest__content--title">{{ card.title }}</h3>
          <p class="latest__content--description">{{ card.description }}</p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        {
          date: "5 ديسمبر 2024",
          category: "تكنولوجيا",
          title: "ثورة الخدمات اللوجستية باستخدام الذكاء الاصطناعي",
          description:
            "استكشاف كيف تُحدث التكنولوجيا الذكية ثورة في الكفاءة والابتكار.",
          image: require("../../assets/images/landing-page/latest1.png"),
        },
        {
          date: "7 ديسمبر 2024",
          category: "الاستدامة",
          title: "الخدمات اللوجستية الخضراء: مستقبل سلاسل الإمداد",
          description:
            "تعرف على حلول الاستدامة الحديثة التي تغير الصناعة لصالح الأجيال القادمة.",
          image: require("../../assets/images/landing-page/latest2.png"),
        },
        {
          date: "10 ديسمبر 2024",
          category: "الإنتاجية",
          title: "المستودعات الذكية: الخطوة التالية في الكفاءة",
          description:
            "تعرف على كيفية الاستفادة من التكنولوجيا الحديثة لتعزيز الكفاءة التشغيلية.",
          image: require("../../assets/images/landing-page/latest3.png"),
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.latest {
  margin-bottom: 5rem;
  margin-top: 5rem;
  &__header {
    margin-bottom: 2.5rem;
    &--title {
      font-size: 2.241rem;
      font-weight: 400;
      margin-bottom: 0.5rem;
      color: #1e293b;
    }
    &--subtitle {
      font-size: 1.125rem;
      color: #334155;
      font-weight: 400;
      margin-bottom: 0rem;
    }
  }

  &__content {
    margin-top: 2rem;
    &__details {
      display: flex;
      align-items: center;
      margin-bottom: 0.75rem;
      &--date {
        margin-bottom: 0rem;
        margin-top: 0.25rem;
        margin-inline-end: 0.978rem;
        color: #64748b;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1rem;
      }
      &--category {
        background-color: #c7dbee !important;
        font-size: 0.75rem;
        line-height: 1rem;
        font-weight: 400;
        margin-bottom: 0rem;
        padding: 0.375rem 0.75rem;
        border-radius: 0.938rem;
      }
    }
    &--title {
      font-size: 1.125rem;
      font-weight: 400;
      color: #0f172a;
      line-height: 1.5rem;
      margin-bottom: 1.25rem;
    }
    &--description {
      color: #475569;
      line-height: 1.5rem;
      font-size: 0.875rem;
      font-weight: 400;
      margin-bottom: 0rem;
    }
  }
}

</style>
