<template>
  <div class="landing-page">
    <v-row align="center" justify="space-between" no-gutters>
      <!-- Text Section -->
      <v-col cols="12" md="12" class="text-section">
        <v-container>
       <div class="hero-section__content">
         <h1 class="title">ببساطة بسهولة شحن</h1>
        <p class="subtitle">
          قم بتحويل عملياتك اللوجستية مع منصة كاشيب المتطورة، المصممة للاندماج بسلاسة مع عملك لتعزيز الكفاءة.
        </p>
        <div class="button-group">
          <v-btn class="primary-btn" outlined>
            أضف تفاصيل الشحنة
          </v-btn>
          <v-btn class="secondary-btn" text>
            تواصل مع خبراء كاشيب
            <img class="secondary-btn--icon" src="../../assets/images/landing-page/heroArrowI.png" />
          </v-btn>
        </div>
       </div>
        </v-container>
      </v-col>
      <!-- Image Section -->
      <v-col cols="12" md="6" class="image-section">
        <img
          src="../../assets/images/landing-page/herooo.png"
          alt="Loading Dock"
          aspect-ratio="1.3"
          class="image-box"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "LandingPage",
};
</script>

<style lang="scss" scoped>
.landing-page {
  position: relative;
  overflow: hidden;
  background: #c7dbee;
  //margin-top: 4.625rem;
  height: 48.5rem;
  display: flex;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0; /* Changed from left */
    height: 100%;
    width: 75%;
    background: linear-gradient(to left, #eff6ff, #ffffff); /* Adjusted direction */
    clip-path: polygon(20% 100%, 100% 100%, 100% 0, 0 0); /* Reversed */
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0; /* Changed from right */
    height: 100%;
    width: 58%;
    background: linear-gradient(to bottom, #a9c2dd, #f8fbff);
    clip-path: polygon(53% 0, 0 0, 0 100%, 100% 100%); /* Reversed */
    z-index: 1;
  }

  .text-section {
    position: relative;
    z-index: 2;
    text-align: start;

    .title {
      font-size: 3.728rem !important;
      font-weight: 400;
      margin-bottom: 2.125rem;
      line-height: 3.75rem;
      color: #0f172a;
    }
    .subtitle {
      font-size: 1rem;
      color: #334155;
      margin-bottom: 3.188rem;
      line-height: 1.5rem;
    }
    .button-group {
      display: flex;

      .primary-btn {
        font-size: 1.125rem;
        border-radius: 0.5rem;
        width: 13.638rem;
        height: 3.25rem;
        margin-inline-end: 0.5rem;
      }

      .secondary-btn {
        font-size: 1rem;
        border-radius: 0.5rem;
        display: flex;
        justify-content: flex-start;
        &--icon {
          rotate: 180deg;
          width: 0.875rem;
          height: 0.75rem;
          margin-inline-start: 0.23rem;
        }
      }
    }
  }

  .image-section {
    //position: relative;
    position: absolute;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: start; /* Align image to the start for RTL */
    .image-box {
      border-radius: 0.5rem;
    }
  }

  .button-group {
    .primary-btn {
      background-color: #5c7aab;
      color: white;
      width: 13.625rem;
      height: 3.25rem;
    }
    .secondary-btn {
      color: #0f172a;
      width: 13.625rem;
      height: 3.25rem;
    }
  }
}
.hero-section__content{
  width: 33.677rem;
}
</style>
