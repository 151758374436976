<template>
  <footer class="footer">
    <v-container>
    <v-row class="footer__row">
      <!-- Logo and Language Switcher -->
      <v-col cols="12" md="3" class="footer__row__logo">
        <div class="footer__logo">
          <img src="../../../assets/images/footerLogo.png" alt="Logo" />
        </div>
        <div class="footer__language-switcher">
          <p>اللغة</p>
          <div>
            <a href="#" class="footer__link">EN</a> <span>|</span>
            <a href="#" class="footer__link">AR</a>
          </div>
        </div>
      </v-col>

      <!-- Services Section -->
      <v-col cols="12" md="2" class="footer__row__services">
        <h3 class="footer__title">الخدمات</h3>
        <ul class="footer__list">
          <li><a href="#" class="footer__link">شحن اونلاين</a></li>
          <li><a href="#" class="footer__link">التخليص الجمركي</a></li>
          <li><a href="#" class="footer__link">النقل البحري</a></li>
          <li><a href="#" class="footer__link">النقل الجوي</a></li>
          <li><a href="#" class="footer__link">النقل البري</a></li>
          <li><a href="#" class="footer__link">التخزين</a></li>
        </ul>
      </v-col>

      <!-- Quick Links Section -->
      <v-col cols="12" md="2" class="footer__row__links">
        <h3 class="footer__title">روابط مهمة</h3>
        <ul class="footer__list">
          <li><a href="#" class="footer__link">عن الشركة</a></li>
          <li><a href="#" class="footer__link">خدمات كاشيب</a></li>
          <li><a href="#" class="footer__link">كيف يعمل كاشيب</a></li>
          <li><a href="#" class="footer__link">خريطة الموقع</a></li>
          <li><a href="#" class="footer__link">الشروط والأحكام</a></li>
          <li><a href="#" class="footer__link">سياسة الخصوصية</a></li>
        </ul>
      </v-col>

      <!-- Contact Section -->
      <v-col cols="12" md="3" class="footer__row__contact">
        <div>
          <div class="footer__row__contact__location">
            <img src="../../../assets//images/footer-location.png" />
            <h3 class="footer__title">العنوان</h3>
          </div>
          <p class="footer__text">
            لوريم إيبسوم هو نص تجريبي 
            <br/>
            للطباعة والتنسيق.
          </p>
        </div>
        <div>
          <div class="footer__row__contact__call">
            <img src="../../../assets//images/call-footer.png" />
            <h3 class="footer__title">اتصل بنا</h3>
          </div>
          <p class="footer__text">+966 52 8121947</p>
        </div>
        <!--Info Section -->
      </v-col>
      <v-col cols="12" md="2" class="footer__row__info">
        <h3 class="footer__title">مزيد من المعلومات</h3>
        <div>
          <div class="footer__social-icons">
            <a href="#" class="footer__icon">
              <img src="../../../assets//images/twitter-footer.svg" />
            </a>
            <a href="#" class="footer__icon">
              <img src="../../../assets//images/instgram-footer.svg" />
            </a>
            <a href="#" class="footer__icon">
              <img src="../../../assets//images/fb-footer.svg" />
            </a>
          </div>
          <p class="footer__social-text">كل الحقوق محفوظة</p>
        </div>
      </v-col>
    </v-row>
    </v-container>
  </footer>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  background-color: #374151; // Dark gray
 // padding: 2.5rem 1.25rem;
  //margin-top: 3.127rem;
  height: 16.438rem;
      display: flex;
    align-items: center;
  .container {
    padding-bottom: 0rem;
  }

  &__row {
    &__logo {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &__contact,
    &__info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__location,
      &__call {
        display: flex;
        align-items: center;
        img {
          display: inline-block;
          margin-inline-end: 0.706rem;
          width: 0.853rem;
          height: 1.023rem;
        }
      }
    }
  }
  &__link {
    font-size: 0.875rem;
    font-weight: 400;
    color: #cacaca;
    margin-top: 0.625rem;
    display: inline-block;
  }

  &__logo img {
    width: 6.316rem;
    height: 2.061rem;
  }

  &__language-switcher {
    color: white;
    font-size: 0.875rem;
    text-align: start;
    span {
      color: #fff;
      font-weight: bold;
    }
    p {
      margin-bottom: 0rem;
    }
    div {
      width: 4.465rem;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      span {
        color: #bfc2c8;
      }
    }
    a {
      color: #fff;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__title {
    font-size: 0.938rem;
    font-weight: bold;
    color: #fff;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 0rem;
    }

    a {
      font-size: 0.875rem;
      font-weight: 400;
      color: #cacaca;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__text {
    font-size: 0.875rem;
    font-weight: 400;
    color: #cacaca;
    margin-top: 0.625rem;
    margin-bottom: 0rem;
  }

  &__social-icons {
    display: flex;
    a {
      img {
        width: 2.403rem;
        height: 2.403rem;
      }
      &:nth-child(2) {
        margin: 0rem 0.868rem;
      }
    }
  }
  &__social-text {
    font-size: 0.875rem;
    font-weight: 400;
    color: #cacaca;
    text-align: start;
    margin-top: 0.938rem;
    margin-bottom: 0rem;
  }
}
</style>
