<template>
  <v-container class="blog-section">
    <div class="blog-section__content">
      <p class="blog-section__content--header">مستعد لتبدأ مع كاشيب؟</p>
      <h1 class="blog-section__content--title">تحدث الى الخبير</h1>
      <p class="blog-section__content--text">
        لوريم إيبسوم هو نص شكلي (بمعنى أن الغاية منه هي الشكل فقط) يُستخدم
        <br />
        في صناعات التصميم والطباعة. يُعتبر هذا النص بمثابة نص تجريبي يُستخدم
        لملء
        <br />
        المساحات في التصاميم ليظهر للعملاء
      </p>
      <div class="blog-section__content--btn">
        <v-btn depressed large class="rounded-lg"> تواصــل مع الخبيــر </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.blog-section {
  background-color: #c53c1c;
  border-radius: 1.875rem;
  min-height: 28.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("@/assets/images/landing-page/path.png");
  background-position: left calc(100% - 0.75rem);
  background-repeat: no-repeat;
  background-size: 100% auto;
  &__content {
    color: #ffff;
    text-align: center;
    &--header {
      margin-bottom: 0.25rem;
    }
    &--title {
      font-size: 2.5rem;
      margin-bottom: 0.5rem;
      
    }
    &--btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.625rem;
      button {
        background-color: #ffff !important;
        color: #c53c1c !important;
        font-weight: 700;
      }
    }
  }

}
</style>
>
