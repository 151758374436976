<template>
  <v-container class="services py-0">
    <v-row justify="center" class="services__header">
      <v-col cols="12" class="py-0">
        <p class="services__header--text">
          اختبر الخدمات الرقمية والآلية بسلاسة مع كاشيب
        </p>
        <h2 class="services__header--title">كل ما تحتاجه</h2>
        <p class="services__header--description">
          اكتشف حلولنا الشاملة لتلبية جميع احتياجات إدارة الخدمات اللوجستية
          الخاصة بك.
        </p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        v-for="(feature, index) in features"
        :key="index"
        cols="12"
        md="4"
        lg="4"
        class=""
      >
          <v-row class="services__content">
            <v-col class="py-0" cols="2">
            <img class="services__content--icon" :src="feature.icon" />
            </v-col>
            <v-col cols="8" class="services__content__section">
              <v-row><h3 class="feature-title">{{ feature.title }}</h3></v-row>
              <v-row><p class="services__content--description">
            {{ feature.description }}
          </p></v-row>
            </v-col>
          </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      features: [
        {
          icon: require("../../assets/images/landing-page/service-icon1.svg"),
          title: "التخليص الجمركي",
          description:
            "تنقل عبر اللوائح الجمركية المعقدة مع خدماتنا المتخصصة لضمان شحنات دولية سلسة.",
        },
        {
          icon: require("../../assets/images/landing-page/service-icon2.svg"),
          title: "الشحن الجوي",
          description:
            "حلول شحن جوي سريعة وموثوقة للبضائع الحساسة للوقت، مع انتشار عالمي وأسعار تنافسية.",
        },
        {
          icon: require("../../assets/images/landing-page/service-icon3.svg"),
          title: "الشحن البحري",
          description:
            "خدمات شحن بحري فعّالة من حيث التكلفة للشحنات ذات الحجم الكبير، مع خيارات حاويات مرنة وتوحيد الشحنات.",
        },
        {
          icon: require("../../assets/images/landing-page/service-icon4.svg"),
          title: "الشحن البري",
          description:
            "حلول شاملة للنقل البري والسكك الحديدية للشحنات المحلية وعبر الحدود.",
        },
        {
          icon: require("../../assets/images/landing-page/service-icon5.svg"),
          title: "الحجز الفوري",
          description:
            "قم بتبسيط عملية الشحن الخاصة بك من خلال منصتنا المريحة للحجز الفوري للحصول على الأسعار والحجوزات الفورية.",
        },
        {
          icon: require("../../assets/images/landing-page/service-icon6.svg"),
          title: "المستودعات",
          description:
            "خدمات تخزين وتوزيع متطورة لتحسين عمليات سلسلة التوريد الخاصة بك.",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.services {
  margin-top: 5.063rem;
  margin-bottom: 5.063rem;
  &__header {
    text-align: center;
    &--text {
      color: #5c7aab;
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
    &--title {
      font-size: 2.25rem;
      color: #111827;
      font-weight: 400;
      margin-bottom: 1.5rem;
    }
    &--description {
      font-size: 1.125rem;
      color: #4b5563;
      margin-bottom: 0rem;
    }
  }
  &__content {
    margin-top: 4rem;
    &--icon {
        width: 2.5rem;
        height: 2.5rem;
       // margin-inline-end: 1.5rem;
      }
    &--description {
      margin-top: 0.625rem;
      margin-bottom: 0rem;
    }
    &__section {
      margin-inline-start: 0.75rem;
    }
  }
}
</style>
