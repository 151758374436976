

<template>
  <v-app class="app" >
      <navbar-component />
      <v-container fluid :class="routeName == 'home'?'wrapped-container':'no-wrapped-container'"  >
        <sidebar-component v-if="isLoggedIn" />
        <router-view></router-view>
      </v-container>
      <footer-component v-if="!footerHiddenView.includes($route.name)" />

  </v-app>

</template>

<script>
import NavbarComponent from "@/common/components/Layout/NavbarComponent.vue";
import SidebarComponent from "@/common/components/Layout/SidebarComponent.vue";
import FooterComponent from "@/common/components/Layout/FooterComponent.vue";
export default {
  name: "App",
  components: { NavbarComponent, FooterComponent, SidebarComponent },
  data() {
    return {
      routeName : this.$route.name,
      footerHiddenView: [
        "login",
        "forget-password",
        "otp",
        "form-succeed",
        "reset-password",
        "register",
        "shipping-requests",
        "new-request",
        "not-found",
        "request-details",
        "service-providers",
        "support",
        "dashboard",
        "terms"
      ],
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
   watch: {
     $route(to) {
       this.routeName = to.name;
    },
  },
};
</script>

<style lang="scss">
@import "../src/scss/main.scss";
</style>