<template>
  <div  class="nav-bar">
    <v-container class="py-0">
    <v-toolbar dense height="74" flat>
      <div
        v-if="!isLoggedIn"
        class="navbar d-flex align-center h-100 w-100 mx-auto "
      >
        <div v-if="$store.getters.isLoggedIn" class="navbar__logo ml-md-9">
          <img width="90px" src="@/assets/images/logo-blue.svg" />
        </div>
        <router-link v-else class="navbar__logo ml-md-9" :to="{ name: 'home' }">
          <img width="90px" src="@/assets/images/logo-blue.svg" />
        </router-link>
        <router-link
          v-for="link in navLinks"
          :key="link.name"
          :exact="true"
          :to="{ name: link.name }"
          class="navbar__link d-flex align-center h-100 black--text text-decoration-none"
          :class="{ 'ml-9': !link.lastChild }"
        >
          {{ link.label }}
        </router-link>

        <v-spacer></v-spacer>

        <router-link
          exact
          :to="{ name: 'register' }"
          class="navbar__link d-flex align-center h-100 black--text text-decoration-none ml-9"
        >
          انضم الينـا
        </router-link>
        <v-btn
          @click="$router.push({ name: 'login' })"
          dark
          color="primary"
          class="rounded-lg"
        >
          تسجيل الدخول
        </v-btn>
      </div>
      <div
        v-if="isLoggedIn"
        class="navbar d-flex align-center justify-between w-100 mx-auto register-nav"
      >
        <div class="d-flex align-center gap-22">
          <div v-if="$store.getters.isLoggedIn" class="navbar__logo">
            <img width="90px" src="@/assets/images/logo-blue.svg" />
          </div>
          <router-link v-else class="navbar__logo" :to="{ name: 'home' }">
            <img width="90px" src="@/assets/images/logo-blue.svg" />
          </router-link>

          <img src="@/assets/images/vertical-seperator.svg" />
          <div class="d-flex align-center gap-12">
            <img width="22px" class="mb-1" src="@/assets/images/goodbye.svg" />
            <span class="text-sm font-bold"
              >أهلاً {{ $store.getters.userInfo.fullName }}</span
            >
          </div>
          <img width="1px" src="@/assets/images/vertical-seperator.svg" />
          <div class="d-flex align-center gap-14 cursor-pointer">
            <img src="@/assets/images/search.svg" />
            <span class="lightgray--text font-normal text-xs"
              >ابحـث في كاشيـب</span
            >
          </div>
        </div>
        <div class="d-flex align-center gap-30">
          <div class="d-flex align-center gap-8 cursor-pointer">
            <img src="@/assets/images/globe.svg" />
            <span class="black--text font-normal text-xs">ع</span>
          </div>
          <v-badge
            class="cursor-pointer"
            :content="1"
            :value="1"
            color="error"
            overlap
          >
            <img src="@/assets/images/bell.svg" />
          </v-badge>
          <img class="rounded-lg" src="@/assets/images/profile-picture.svg" />
        </div>
      </div>
    </v-toolbar>
  </v-container>
  </div>
</template>






<script>
export default {
  data() {
    return {
      navLinks: [
        { name: "home", label: "الرئيسية" },
        { name: "benefits", label: "مميـزات الشركـة" },
        { name: "about", label: "نبـذة عن الشركـة" },
        { name: "work-steps", label: "خطـوات العمل" },
        { name: "join-us", label: "انضم الينـا" },
        { name: "common", label: "الأسئلة الشائعـة", lastChild: true },
      ],
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  &__link {
    font-size: 14px;
    font-weight: 400;
    position: relative;

    &:not(:last-child) {
      margin-left: 9px;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: $error;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
    &:hover {
      color: $primary;
      &::after {
        transform: scaleX(1);
        transform-origin: bottom right;
      }
      .navbar__account__icon {
        color: inherit !important;
        transform: rotateX(180deg);
      }
    }
    &.router-link-active {
      color: $primary;
      &::after {
        transform: scaleX(1);
      }
    }
  }
}
.nav-bar {
  background-color: #FFFF;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
}
::v-deep .v-toolbar__content, .v-toolbar__extension {
  padding: 0px;
}
.register-nav {
  padding: 16px;
}
</style>